import {
  BASE_CRITICAL_HIT,
  BASE_DODGE,
  BASE_ENERGY,
  BASE_ENERGY_REGENERATION,
  BASE_RESILIENCE,
  BONUS,
  getBaseHealth,
  getBonus,
  getClass,
  getGuildBonuses,
  getItem,
  getMaxLevel,
  getPublicBuild,
  getRandomFactionAndClass,
  getSkillBonuses,
  getUserBuild,
  removeBuild,
  saveBuild,
  TWO_HANDED_SKILLS,
} from "@/api";
import {
  calculateCheck,
  extractBonus,
  filterSlots,
  getLocal,
  localRemove,
  localSave,
  skillPointsFromLevel,
  Stats,
} from "@/utils";
import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);
import { useReactiveMap } from "vue-reactive-collection";

export default {
  namespaced: true,
  state: {
    loading: true,
    clazz: null,
    faction: null,
    maxLevel: 0,
    level: 0,
    base: null,
    factionBonuses: [],
    slots: Array.from({ length: 15 }, () => null),
    expose: localStorage.getItem("debug") || false,
    build: 0,
    buildName: "",
    buildVisibility: false,
    originalBuild: 0,
    guildBonuses: [],
    guild: [],
    skills: useReactiveMap(),
    haircutId: 1,
    hairColor: 0x808080,
    gender: 0,
  },
  mutations: {
    setLoading(state, load) {
      state.loading = load;
    },
    setFaction(state, faction) {
      state.faction = faction;
    },
    setClass(state, clazz) {
      state.clazz = clazz;
      for (let i = 0; i < state.slots.length; i++) {
        if (
          state.slots[i] !== null &&
          state.slots[i].skill &&
          !clazz.skills.includes(state.slots[i].skill)
        ) {
          Vue.set(state.slots, i, null);
        }
      }
      // Dual hand skill
      if (
        state.slots[5] !== null &&
        state.slots[5].skill !== 40 &&
        !clazz.skills.includes(53)
      ) {
        Vue.set(state.slots, 5, null);
      }
    },
    setMaxLevel(state, level) {
      if (level % 2 !== 0) {
        level++;
      }
      state.maxLevel = level;
      if (state.level === 0) {
        state.level = level;
      }
    },
    setLevel(state, level) {
      state.level = level;
      for (let i = 0; i < state.slots.length; i++) {
        if (state.slots[i] !== null && state.slots[i].level > level) {
          Vue.set(state.slots, i, null);
        }
      }
    },
    setBaseHealth(state, health) {
      state.base = health;
    },
    setFactionBonuses(state, bonuses) {
      state.factionBonuses = bonuses;
    },
    setSlot(state, { slot, item }) {
      Vue.set(state.slots, slot, item);
      if (
        slot === 5 &&
        state.slots[3] !== null &&
        TWO_HANDED_SKILLS.includes(state.slots[3].skill)
      ) {
        Vue.set(state.slots, 3, null);
      } else if (
        slot === 3 &&
        item !== null &&
        TWO_HANDED_SKILLS.includes(item.skill) &&
        state.slots[5] !== null
      ) {
        Vue.set(state.slots, 5, null);
      }
    },
    setBuild(
      state,
      { id, name, visibility, original, haircutId, hairColor, gender }
    ) {
      state.build = id;
      state.buildName = name;
      state.buildVisibility = visibility;
      state.originalBuild = original;
      state.haircutId = haircutId;
      state.hairColor = hairColor;
      state.gender = gender;
    },
    setGuildBonuses(state, list) {
      state.guildBonuses = list;
    },
    setGuildParam(state, { skillId, level }) {
      let list = state.guild.filter((g) => g.skillId !== skillId);
      if (level > 0) {
        list = list.concat([
          {
            skillId,
            level,
          },
        ]);
      }
      state.guild = list;
    },
    setGuildParams(state, list) {
      state.guild = list;
    },
    resetSlots(state) {
      state.slots = Array.from({ length: 15 }, () => null);
    },
    resetBuild(state) {
      state.build = 0;
      state.originalBuild = 0;
      state.buildName = "";
      state.buildVisibility = false;
      state.guild = [];
    },
    resetSkills(state) {
      state.skills.value.clear();
    },
    setSkills(state, skills) {
      state.skills.value.clear();
      skills.forEach((s) => state.skills.value.set(s.skillId, s.level));
    },
    setGender(state, gender) {
      state.gender = gender;
    },
    setHair(state, hair) {
      state.haircutId = hair;
    },
    setGenderAndHair(state, { gender, hair }) {
      state.gender = gender;
      state.haircutId = hair;
    },
    setHairColor(state, color) {
      state.hairColor = color;
    },
  },
  actions: {
    randomFactionAndClass(ctx, locale) {
      ctx.commit("setLoading", true);
      return getRandomFactionAndClass(locale)
        .then((e) => {
          ctx.commit("setFaction", {
            id: e.data.factionId,
            name: e.data.factionName,
            icon: e.data.factionIcon,
            skill: e.data.skill,
          });
          ctx.commit("setClass", {
            id: e.data.classId,
            name: e.data.className,
            icon: e.data.classIcon,
            faction: e.data.factionId,
            skills: e.data.skills,
          });
        })
        .finally(() => {
          ctx.commit("setLoading", false);
        });
    },
    getMaxLevel(ctx) {
      ctx.commit("setLoading", true);
      return getMaxLevel()
        .then((e) => {
          ctx.commit("setMaxLevel", e.data.maxLevel);
        })
        .finally(() => {
          ctx.commit("setLoading", false);
        });
    },
    initialize(ctx, locale) {
      return Promise.all([
        ctx.dispatch("randomFactionAndClass", locale),
        ctx.dispatch("getMaxLevel"),
        ctx.dispatch("getGuildBonuses", locale),
      ])
        .then(() => {
          const tasks = [
            ctx.dispatch("getBaseHealth", {
              clazz: ctx.state.clazz.id,
              level: ctx.state.level,
            }),
          ];
          if (ctx.state.faction.skill !== null) {
            tasks.push(
              ctx.dispatch("getFactionSkill", ctx.state.faction.skill)
            );
          }
          return Promise.all(tasks);
        })
        .finally(() => {
          ctx.commit("setLoading", false);
        });
    },
    getBaseHealth(ctx, { clazz, level }) {
      return getBaseHealth(clazz, level).then((e) => {
        ctx.commit("setBaseHealth", e.data);
      });
    },
    getFactionSkill(ctx, id) {
      return getSkillBonuses(id, 1).then((e) => {
        ctx.commit("setFactionBonuses", e.data.list);
      });
    },
    saveLocal(ctx, { isNew, name }) {
      const buildId = ctx.state.build > 0 ? 0 : ctx.state.build;
      const skills = Array.from(ctx.state.skills.value.entries()).map((s) => {
        return {
          skillId: s[0],
          level: s[1],
        };
      });
      const id =
        Math.abs(
          localSave({
            id: isNew ? 0 : Math.abs(buildId),
            userId: 0,
            name: name,
            classId: ctx.state.clazz.id,
            classIcon: ctx.state.clazz.icon,
            className: ctx.state.clazz.name,
            level: ctx.state.level,
            public: false,
            slots: filterSlots(ctx.state.slots),
            guild: ctx.state.guild,
            skills,
            haircutId: ctx.state.haircutId,
            hairColor: ctx.state.hairColor,
            gender: ctx.state.gender,
          })
        ) * -1;
      ctx.commit("setBuild", {
        id,
        name: name,
        visibility: false,
        haircutId: ctx.state.haircutId,
        hairColor: ctx.state.hairColor,
        gender: ctx.state.gender,
      });
    },
    saveServer(ctx, { isNew, name, visibility }) {
      let slots = filterSlots(ctx.state.slots);
      const buildId = ctx.state.build < 0 ? 0 : ctx.state.build;
      const skills = Array.from(ctx.state.skills.value.entries()).map((s) => {
        return {
          skillId: s[0],
          level: s[1],
        };
      });
      return saveBuild({
        id: isNew ? 0 : buildId,
        userId: ctx.rootState.id,
        name: name,
        classId: ctx.state.clazz.id,
        level: ctx.state.level,
        public: visibility,
        slots,
        guild: ctx.state.guild,
        skills,
        check: calculateCheck(
          slots,
          ctx.state.guild,
          ctx.state.clazz.id,
          ctx.state.level,
          name,
          skills
        ),
        haircutId: ctx.state.haircutId,
        hairColor: ctx.state.hairColor,
        gender: ctx.state.gender,
      }).then((e) => {
        ctx.commit("setBuild", {
          id: e.data.id,
          name: e.data.name,
          visibility: e.data.public,
          haircutId: e.data.haircutId,
          hairColor: e.data.hairColor,
          gender: e.data.gender,
        });
      });
    },
    loadServer(ctx, { locale, id }) {
      return (
        ctx.rootState.id > 0 ? getUserBuild(id) : getPublicBuild(id)
      ).then((e) => {
        return ctx.dispatch("loadBuild", {
          locale,
          build: e.data,
        });
      });
    },
    loadLocal(ctx, { locale, id }) {
      let build = getLocal(Math.abs(id));
      if (!build) {
        return Promise.reject();
      }
      build.id *= -1;
      return ctx.dispatch("loadBuild", {
        locale,
        build,
      });
    },
    loadBuild(ctx, { locale, build }) {
      ctx.commit("setBuild", {
        id:
          build.userId === ctx.rootState.id || build.userId === 0
            ? build.id
            : 0,
        name: build.name,
        visibility: build.public,
        original: build.id,
        haircutId: build.haircutId || 1,
        hairColor: build.hairColor || 0x808080,
        gender: build.gender || 0,
      });
      return Promise.all([
        getClass(locale, build.classId),
        getBaseHealth(build.classId, build.level),
      ])
        .then((es) => {
          let cls = es[0];
          ctx.commit("setFaction", {
            id: cls.data.factionId,
            name: cls.data.factionName,
            icon: cls.data.factionIcon,
            skill: cls.data.skill,
          });
          ctx.commit("setClass", {
            id: cls.data.classId,
            name: cls.data.className,
            icon: cls.data.classIcon,
            faction: cls.data.factionId,
            skills: cls.data.skills,
          });
          ctx.commit("setLevel", build.level);
          ctx.commit("setBaseHealth", es[1].data);
          ctx.commit("setGuildParams", build.guild);
          ctx.commit("setSkills", build.skills || []);
          ctx.commit("resetSlots");
          if (cls.data.skill) {
            getSkillBonuses(cls.data.skill, 1).then((e) => {
              ctx.commit("setFactionBonuses", e.data.list);
            });
          }
          const res = [];
          for (const slot of build.slots) {
            res.push(
              getItem(locale, slot.itemId).then((e) => {
                return {
                  slot,
                  item: e.data,
                };
              })
            );
          }
          return res.length > 0 ? Promise.all(res) : Promise.resolve();
        })
        .then((e) => {
          let cache = [];
          const ids = [];
          for (let row of e) {
            cache = cache.concat(extractBonus(row.item, ids));
          }
          const req = [];
          for (let row of e) {
            if (row.slot.crystalId && !ids.includes(row.slot.crystalId)) {
              console.warn("[Store] No bonus cached", row.slot.crystalId);
              req.push(getBonus(locale, row.slot.crystalId));
            }
            if (row.slot.runeId && !ids.includes(row.slot.runeId)) {
              console.warn("[Store] No bonus cached", row.slot.runeId);
              req.push(getBonus(locale, row.slot.runeId));
            }
          }
          return (req.length > 0 ? Promise.all(req) : Promise.resolve()).then(
            (bonuses) => {
              if (bonuses) {
                bonuses.forEach((b) => cache.push(b.data));
              }
              for (let row of e) {
                let crystal = null;
                if (row.slot.crystalId) {
                  const bonus = cache.find((b) => b.id === row.slot.crystalId);
                  crystal = {
                    bonus: row.slot.crystalId,
                    icon: bonus.icon,
                    name: bonus.name,
                    params: bonus.params,
                    value: row.slot.crystalValue,
                    extra: (row.slot.modifiers & 2) !== 0,
                  };
                }
                let rune = null;
                if (row.slot.runeId) {
                  const bonus = cache.find((b) => b.id === row.slot.runeId);
                  rune = {
                    bonus: row.slot.runeId,
                    icon: bonus.icon,
                    name: bonus.name,
                    params: bonus.params,
                    value: row.slot.runeValue,
                    extra: (row.slot.modifiers & 1) !== 0,
                  };
                }
                ctx.commit("setSlot", {
                  slot: row.slot.slot,
                  item: {
                    ...row.item,
                    power: row.slot.power,
                    crystal,
                    rune,
                  },
                });
              }
            }
          );
        });
    },
    getGuildBonuses(ctx, locale) {
      return getGuildBonuses(locale).then((e) => {
        ctx.commit("setGuildBonuses", e.data.list);
      });
    },
    removeBuild(ctx, id) {
      let res = Promise.resolve();
      if (id < 0) {
        localRemove(id);
      } else if (id > 0) {
        res = removeBuild(id);
      }
      ctx.commit("resetSlots");
      ctx.commit("resetBuild");
      return res;
    },
  },
  getters: {
    stats(state) {
      if (state.base === null) {
        return new Stats();
      }
      const stats = new Stats();
      stats.add(BONUS.HEALTH, state.base.health);
      stats.add(BONUS.HEALTH_REGENERATION, state.base.regeneration);
      stats.add(BONUS.ENERGY, BASE_ENERGY);
      stats.add(BONUS.ENERGY_REGENERATION, BASE_ENERGY_REGENERATION);
      stats.add(BONUS.PHYSIC_DAMAGE, state.level * 100);
      stats.add(BONUS.MAGIC_DAMAGE, state.level * 100);
      stats.add(BONUS.CRITICAL_HIT, BASE_CRITICAL_HIT);
      stats.add(BONUS.RESILIENCE, BASE_RESILIENCE);
      stats.add(BONUS.DODGE, BASE_DODGE);
      for (const bonus of state.factionBonuses) {
        stats.add(bonus.bonusId, bonus.value);
      }
      for (let i = 0; i < state.slots.length; i++) {
        const slot = state.slots[i];
        if (slot !== null) {
          stats.addItem(slot, i === 5 && slot.itemType !== 3);
        }
      }
      if (state.slots[3] !== null) {
        stats.setWeaponType(
          state.slots[3].itemType,
          state.slots[3].skill,
          false
        );
      }
      if (state.slots[5] !== null && state.slots[5].itemType !== 3) {
        stats.setWeaponType(
          state.slots[5].itemType,
          state.slots[5].skill,
          true
        );
      }
      state.guild.forEach((g) => {
        const skill = state.guildBonuses.find((s) => s.id === g.skillId);
        if (skill) {
          skill.bonuses.forEach((b) => {
            if (b.level === g.level) {
              stats.addBonus(b.bonus, b.value);
            }
          });
        }
      });
      stats.execute();
      return stats;
    },
    health(state, getters) {
      return Math.floor(getters.stats.get(BONUS.HEALTH) / 100);
    },
    energy(state, getters) {
      return Math.floor(getters.stats.get(BONUS.ENERGY) / 100);
    },
    physicDamage(state, getters) {
      return Math.floor(getters.stats.get(BONUS.PHYSIC_DAMAGE) / 100);
    },
    magicDamage(state, getters) {
      return Math.floor(getters.stats.get(BONUS.MAGIC_DAMAGE) / 100);
    },
    physicDefence(state, getters) {
      return Math.floor(getters.stats.get(BONUS.PHYSIC_DEFENCE) / 100);
    },
    magicDefence(state, getters) {
      return Math.floor(getters.stats.get(BONUS.MAGIC_DEFENCE) / 100);
    },
    skillPoints(state) {
      return skillPointsFromLevel(state.level);
    },
  },
};
