<template>
  <div class="icon" :style="{ backgroundImage: `url('${icon}')` }" />
</template>

<script>
export default {
  name: "stats-icon",
  computed: {
    icon() {
      return require("../assets/stats/" + this.name + ".png");
    },
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.icon {
  background-size: 32px;
  background-repeat: no-repeat;
  image-rendering: pixelated;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  display: inline-block;
  padding: 0;
}
</style>
