<template>
  <div :style="style" class="frame corners bottom">
    <div class="corners top"></div>
    <div class="title">
      <slot name="title" />
    </div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "block-frame",
  props: {
    maxWidth: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    style() {
      if (this.maxWidth) {
        return {
          maxWidth: this.maxWidth + "px",
        };
      }
      return {};
    },
  },
};
</script>

<style scoped>
.frame {
  background-image: url("../assets/bg.png");
  background-size: 112px 64px;
  image-rendering: pixelated;
  min-height: 128px;
  position: relative;
  border: 1px solid #e6ce63;
  margin: 0 auto;
}
.title {
  min-height: 44px;
  background-color: rgba(207, 102, 25, 0.6);
  position: relative;
  text-align: center;
  color: #ffff00;
  text-shadow: -1px 0 1px #000, 0 -1px 1px #000, 1px 0 1px #000, 0 1px 1px #000;
  line-height: 44px;
  font-size: 1.2rem;
  user-select: none;
}
.title::after {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 0;
  border-top: 1px solid #df7747;
  border-bottom: 1px solid #6f4737;
  width: 100%;
}
.corners {
  position: relative;
}
.corners::after,
.corners::before {
  content: " ";
  position: absolute;
  background-image: url("../assets/corner.png");
  width: 44px;
  height: 42px;
  background-size: 44px 42px;
  image-rendering: pixelated;
  z-index: 2;
  pointer-events: none;
}
.corners::before {
  left: 0;
}
.corners::after {
  right: 0;
  transform: scaleX(-1);
}
.corners.top::before,
.corners.top::after {
  top: 0;
}
.corners.bottom::before,
.corners.bottom::after {
  bottom: 0;
  transform: scaleY(-1);
}
.corners.bottom::after {
  transform: scale(-1, -1);
}
.content {
  margin: 8px;
  padding: 4px 16px 16px 16px;
  height: calc(100% - 134px);
}
</style>
