import { BONUS } from "@/api";
import { DASH_ROUTES } from "@/router";
import sha1 from "sha1";
const STATS_PERCENT = [
  {
    base: BONUS.PHYSIC_DEFENCE,
    percent: BONUS.PHYSIC_DEFENCE_PERCENT,
  },
  {
    base: BONUS.MAGIC_DEFENCE,
    percent: BONUS.MAGIC_DEFENCE_PERCENT,
  },
  {
    base: BONUS.PHYSIC_DAMAGE,
    percent: BONUS.PHYSIC_DAMAGE_PERCENT,
  },
  {
    base: BONUS.MAGIC_DAMAGE,
    percent: BONUS.MAGIC_DAMAGE_PERCENT,
  },
  {
    base: BONUS.HEALTH_REGENERATION,
    percent: BONUS.HEALTH_REGENERATION_PERCENT,
  },
  {
    base: BONUS.ENERGY_REGENERATION,
    percent: BONUS.ENERGY_REGENERATION_PERCENT,
  },
  {
    base: BONUS.HEALTH,
    percent: BONUS.HEALTH_PERCENT,
  },
  {
    base: BONUS.ENERGY,
    percent: BONUS.ENERGY_PERCENT,
  },
];

const STATS_SUM = [
  {
    base: BONUS.BLOCK,
    sum: BONUS.BLOCK_X2,
  },
  {
    base: BONUS.CRITICAL_HIT,
    sum: BONUS.CRITICAL_HIT_X2,
  },
  {
    base: BONUS.ACCURACY,
    sum: BONUS.ACCURACY_X2,
  },
  {
    base: BONUS.PENETRATION,
    sum: BONUS.PENETRATION_X2,
  },
  {
    base: BONUS.ATTACK_STRENGTH,
    sum: BONUS.ATTACK_STRENGTH_X,
  },
  {
    base: BONUS.SKILL_COOLDOWN,
    sum: BONUS.SKILL_COOLDOWN_X2,
  }
];

export class Stats {
  constructor() {
    this.map = {};
    this.sets = {};
    this.cooldown = 1.46;
    this.isMagic = false;
  }

  add(bonus, value) {
    if (!(bonus in this.map)) {
      this.map[bonus] = 0;
    }
    this.map[bonus] += value;
  }

  get(bonus) {
    if (bonus in this.map) {
      return this.map[bonus];
    }
    return 0;
  }

  execute() {
    for (const item of STATS_PERCENT) {
      this.map[item.base] =
        this.get(item.base) * (1 + this.get(item.percent) / 10000);
    }
    for (const item of STATS_SUM) {
      this.add(item.base, this.get(item.sum));
    }
    // Y = (1 + (X / 500) ^ 1.9) / 10
    const defence = this.get(BONUS.PHYSIC_DEFENCE) / 100;
    const solidity = (1 + Math.pow(defence / 500, 1.9)) * 10;
    this.add(BONUS.SOLIDITY, solidity);
  }

  addItem(item, limit = false) {
    let val = item.value1;
    if (item.power !== 0) {
      val = powerUp(item.itemType, item.skill, item.value1, item.power);
    }
    this.addBonus(item.bonus1, val, limit);
    this.addBonus(item.bonus2, item.value2, limit);
    this.addBonus(item.bonus3, item.value3, limit);
    this.addBonus(item.bonus4, item.value4, limit);

    if (item.itemType === 3 && item.skill === 40) {
      this.add(BONUS.PHYSIC_DEFENCE_PERCENT, 1500);
      this.add(BONUS.MAGIC_DEFENCE_PERCENT, 1500);
    }
    const pieces = this.getSetPiece(item.itemSet);
    if (pieces === 2) {
      this.addBonus(item.setBonus1, item.setValue1);
    } else if (pieces === 4) {
      this.addBonus(item.setBonus2, item.setValue2);
    }
    if (item.crystal) {
      if (limit) {
        if (item.crystal.bonus === BONUS.PHYSIC_DAMAGE) {
          this.addBonus(item.crystal.bonus, item.crystal.value * 0.6);
        } else if (item.crystal.bonus === BONUS.MAGIC_DAMAGE) {
          this.addBonus(item.crystal.bonus, item.crystal.value * 0.7);
        } else {
          this.addBonus(item.crystal.bonus, item.crystal.value);
        }
      } else {
        this.addBonus(item.crystal.bonus, item.crystal.value);
      }
    }
    if (item.rune) {
      this.addBonus(item.rune.bonus, item.rune.value);
    }
  }

  addBonus(id, val, limit = false) {
    if (id === null || id === 79) {
      return;
    }
    if (limit) {
      if (id === BONUS.PHYSIC_DAMAGE) {
        val *= 0.6;
      } else if (id === BONUS.MAGIC_DAMAGE) {
        val *= 0.7;
      }
    }
    this.add(id, val);
  }

  getSetPiece(id) {
    if (!(id in this.sets)) {
      this.sets[id] = 0;
    }
    return ++this.sets[id];
  }

  setWeaponType(type, skill, second) {
    const cd = COOLDOWN.find((c) => c.type === type && c.skill.includes(skill));
    if (!cd) {
      return;
    }
    if (second) {
      this.cooldown += cd.value;
      this.cooldown /= 3;
    } else {
      this.cooldown = cd.value;
    }
    this.isMagic = type === 1 && skill === 21;
  }

  getDamagePerSecond() {
    let dmg = this.isMagic
      ? this.get(BONUS.MAGIC_DAMAGE)
      : this.get(BONUS.PHYSIC_DAMAGE);
    dmg *= 1 + this.get(BONUS.ATTACK_STRENGTH) / 10000;
    const spd = this.get(BONUS.ATTACK_SPEED) / 10000;
    return dmg / (this.cooldown * (1 - spd));
  }
}

export function defencePercent(defence) {
  return ((defence * 100) / (defence + 650000)) * 100;
}

const Amplifies = {
  HAND1: [
    [1, 0],
    [1.02, 1],
    [1.04, 2],
    [1.06, 3],
    [1.1, 4],
    [1.14, 5],
    [1.21, 6],
    [1.33, 7],
    [1.49, 8],
    [1.66, 9],
    [1.86, 10],
  ],
  HAND2: [
    [1, 0],
    [1.02, 2],
    [1.04, 4],
    [1.06, 6],
    [1.1, 8],
    [1.14, 10],
    [1.21, 12],
    [1.33, 14],
    [1.49, 16],
    [1.66, 18],
    [1.86, 20],
  ],
  ARMOR: [
    [1, 0],
    [1.06, 10],
    [1.1, 14],
    [1.15, 18],
    [1.24, 22],
    [1.37, 26],
    [1.53, 30],
    [1.72, 34],
    [1.98, 38],
    [2.34, 42],
    [2.85, 46],
  ],
  RING: [
    [1, 0],
    [1.11, 10],
    [1.18, 14],
    [1.25, 18],
    [1.35, 22],
    [1.47, 26],
    [1.63, 30],
    [1.85, 34],
    [2.15, 42],
    [2.52, 42],
    [3, 46],
  ],
  AMULET: [
    [1, 0],
    [1.11, 10],
    [1.18, 14],
    [1.25, 18],
    [1.35, 22],
    [1.47, 26],
    [1.63, 30],
    [1.85, 34],
    [2.15, 38],
    [2.52, 42],
    [3, 46],
  ],
};

const ARMOR_SKILLS = [3, 22, 51];

const COOLDOWN = [
  {
    type: 0,
    skill: [1],
    value: 1.7,
  },
  {
    type: 0,
    skill: [2],
    value: 2.0,
  },
  {
    type: 0,
    skill: [10],
    value: 2.2,
  },
  {
    type: 0,
    skill: [222],
    value: 2.4,
  },
  {
    type: 0,
    skill: [78, 76, 81],
    value: 3.2,
  },
  {
    type: 0,
    skill: [223],
    value: 3.4,
  },
  {
    type: 1,
    skill: [34],
    value: 3.3,
  },
  {
    type: 1,
    skill: [21],
    value: 3.1,
  },
  {
    type: 1,
    skill: [97],
    value: 3.9,
  },
];

function powerUpTable(tab, base, power) {
  return tab[power][0] * base + tab[power][1] * 100;
}

export function powerUp(type, skill, base, power) {
  switch ((skill << 16) | type) {
    case 1 << 16: // Dagger
    case 10 << 16: // Axe
    case 2 << 16: // Sword
    case 222 << 16: // Mace
      return powerUpTable(Amplifies.HAND1, base, power);
    case (21 << 16) | 1: // Staff
    case (34 << 16) | 1: // Bow
    case 76 << 16: // 2h Axe
    case 78 << 16: // 2h Sword
    case 81 << 16: // 2h Mace
    case (97 << 16) | 1: // Crossbow
    case 223 << 16: // Spear
      return powerUpTable(Amplifies.HAND2, base, power);
    case 10: // Ring
      return powerUpTable(Amplifies.RING, base, power);
    case 9: // Amulet
    case 37: // Bracelet
      return powerUpTable(Amplifies.AMULET, base, power);
    default:
      if (ARMOR_SKILLS.includes(skill) || type === 4 || type === 3) {
        return powerUpTable(Amplifies.ARMOR, base, power);
      }
      return 0;
  }
}

export function formatValue(expose, params, i) {
  if (expose) {
    if ((params & 0x01) !== 0) {
      return (i / 100).toFixed(2) + "%";
    }
    return (i / 100).toFixed(1);
  }
  if ((params & 0x01) !== 0) {
    return (Math.floor(i / 10) / 10).toFixed(1) + "%";
  }
  return Math.floor(i / 100);
}

export function filterSlots(slots) {
  return slots
    .map((slot, index) => {
      if (slot) {
        return {
          buildId: 0,
          slot: index,
          itemId: slot.id,
          power: slot.power || 0,
          crystalId: slot.crystal ? slot.crystal.bonus : 0,
          runeId: slot.rune ? slot.rune.bonus : 0,
          crystalValue: slot.crystal ? slot.crystal.value : 0,
          runeValue: slot.rune ? slot.rune.value : 0,
          modifiers:
            (slot.crystal ? slot.crystal.extra * 2 : 0) |
            (slot.rune ? slot.rune.extra * 1 : 0),
        };
      }
      return null;
    })
    .filter((s) => s !== null);
}

function localBuilds() {
  const builds = localStorage.getItem("builds");
  if (builds) {
    const val = JSON.parse(builds);
    if (Array.isArray(val)) {
      // Old save model, upgrade
      console.debug("[Utils] Found old save. Do upgrade.");
      localSaveList(val);
      return val;
    }
    const line = JSON.stringify(val.list);
    const hash = sha1(line + "@+" + line.length);
    if (val.base !== hash) {
      console.warn("[Utils] Saving hash mismatch");
      return [];
    }
    return val.list;
  }
  return [];
}

export function localSave(build) {
  let builds = localBuilds();
  if (build.id === 0) {
    build.id = builds.reduce((m, c) => Math.max(m, c.id), 0) + 1;
  }
  builds = builds.filter((b) => b.id !== build.id).concat(build);
  localSaveList(builds);
  return build.id;
}

export function localRemove(id) {
  id = Math.abs(id);
  let builds = localBuilds();
  builds = builds.filter((b) => b.id !== id);
  localSaveList(builds);
}

export function localSaveList(list) {
  const line = JSON.stringify(list);
  const hash = sha1(line + "@+" + line.length);
  localStorage.setItem(
    "builds",
    JSON.stringify({
      list,
      base: hash,
    })
  );
}

export function listLocal() {
  const builds = localBuilds();
  return builds.map((b) => {
    return {
      id: -b.id,
      userId: b.userId,
      username: "",
      name: b.name,
      classId: b.classId,
      classIcon: b.classIcon,
      className: b.className,
      level: b.level,
      public: false,
    };
  });
}

export function getLocal(id) {
  const builds = localBuilds();
  const build = builds.find((b) => b.id === id);
  if (
    build.skills &&
    !Array.isArray(build.skills) &&
    "effect" in build.skills
  ) {
    console.warn("[Utils] Fix local skills bug 🐛");
    build.skills = [];
  }
  return build;
}

export function extractBonus(item, ids) {
  const cache = [];
  for (let i = 1; i <= 4; i++) {
    if (item["bonus" + i] && !ids.includes(item["bonus" + i])) {
      ids.push(item["bonus" + i]);
      cache.push({
        id: item["bonus" + i],
        icon: item["bonus" + i + "Icon"],
        name: item["bonus" + i + "Name"],
        params: item["bonus" + i + "Params"],
      });
    }
  }
  if (item.itemSet) {
    for (let i = 1; i <= 2; i++) {
      if (item["setBonus" + i] && !ids.includes(item["setBonus" + i])) {
        ids.push(item["setBonus" + i]);
        cache.push({
          id: item["setBonus" + i],
          icon: item["setBonus" + i + "Icon"],
          name: item["setBonus" + i + "Name"],
          params: item["setBonus" + i + "Params"],
        });
      }
    }
  }
  return cache;
}

export function calculateCheck(slots, guile, classId, level, name, skl) {
  return (
    slots.reduce((a, s) => {
      let c = (s.slot << 8) | s.itemId;
      c += s.crystalId * 2 + s.runeId;
      c ^= s.runeValue + s.crystalValue;
      return (a << 8) ^ c;
    }, 7814) ^
    guile.reduce((a, g) => {
      return (a << 8) ^ ((g.level * 12) | g.skillId);
    }, 1951) ^
    skl.reduce((a, s) => {
      return (a << 4) ^ ((s.level * 7) | s.skillId);
    }, 8812) ^
    (((classId << 8) | level | name.length) << 8)
  );
}

export function getNextRoute(current, dir, rights) {
  const list = DASH_ROUTES;
  const pos = list.findIndex((f) => f.route === current);
  if (dir < 0) {
    // back
    for (let i = list.length + pos - 1; i > pos; i--) {
      let next = i - list.length;
      if (next < 0) {
        next += list.length;
      }
      if (list[next].right === 0 || rights.includes(list[next].right)) {
        return list[next].route;
      }
    }
  } else {
    // forward
    for (let i = pos + 1; i < list.length + pos; i++) {
      let next = i;
      if (next > list.length - 1) {
        next -= list.length;
      }
      if (list[next].right === 0 || rights.includes(list[next].right)) {
        return list[next].route;
      }
    }
  }
  return list[pos].route;
}

export function format(holder, args) {
  if (args.length === 0) {
    return holder;
  }
  return holder
    .replace(/%\d{1,2}/g, (v) => {
      return evalArg(args[parseInt(v.substr(1)) - 1]);
    })
    .replace(/\r\n/g, "<br />");
}

function evalArg(arg) {
  switch (arg.valueType) {
    case 1:
      return `${+(arg.value * 100).toFixed(2)}%`;
    default:
      return arg.value.toString();
  }
}

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
}

export function relicColorFix(t, color) {
  if (t === 36) {
    switch (color) {
      case 3:
        return 1;
      case 2:
        return 6;
      case 0:
        return 5;
      default:
        return color;
    }
  }
  return color;
}

export function skillPointsFromLevel(level) {
  return Math.floor(level / 2) + Math.floor(level / 10);
}

export function getUsedSkillPoints(skillMap) {
  return Array.from(skillMap.values()).reduce((a, c) => {
    if (c > 1) {
      a += c - 1;
    }
    return a;
  }, 0);
}
