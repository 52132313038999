import Vue from "vue";
import App from "./App.vue";
import store from "@/store";
import { i18n } from "@/lang";
import router from "@/router";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import "./registerServiceWorker";

Vue.config.productionTip = true;

new Vue({
  render: (h) => h(App),
  store,
  i18n,
  router,
}).$mount("#app");
