var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slot",class:[
    _vm.mode,
    _vm.type,
    _vm.icon !== null ? 'icon' : '',
    _vm.levelClass,
    _vm.gender !== null ? 'gender-' + _vm.gender : '',
  ],attrs:{"tabindex":"0"},on:{"click":_vm.click}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.icon !== null || _vm.item !== null),expression:"icon !== null || item !== null"}],staticClass:"icon",style:(_vm.style)}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.type !== 'level' && (_vm.level > 0 || _vm.item !== null)),expression:"type !== 'level' && (level > 0 || item !== null)"}],staticClass:"item-level",domProps:{"textContent":_vm._s(_vm.textLevel)}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'level' && _vm.level > 0),expression:"type === 'level' && level > 0"}],staticClass:"level",domProps:{"textContent":_vm._s(_vm.textLevel)}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.value !== 0),expression:"value !== 0"}],class:['value', (_vm.valueParams & 2) !== 0 ? 'extra' : ''],domProps:{"textContent":_vm._s(_vm.textValue)}})])
}
var staticRenderFns = []

export { render, staticRenderFns }