<template>
  <div class="box">
    <div class="top-line" />
    <div class="content">
      <hero-render
        class="hero"
        :gender="gender"
        :hair="haircutId"
        :hair-color="hairColor"
        :race="faction.id"
        v-if="faction !== null && init"
        :helmet="helmet"
        :helmet-color="helmetColor"
        :render-param="helmetParam"
        :chest="chest"
        :chest-color="chestColor"
        :feet="feet"
        :feet-color="feetColor"
        :hands="hands"
        :hands-color="handsColor"
        :cape="cape"
        :cape-color="capeColor"
        :left-handle="leftWeapon"
        :left-handle-color="leftWeaponColor"
        :is2-hand="is2Hand"
        :right-handle="rightWeapon"
        :right-handle-color="rightWeaponColor"
        :is-shield="isShield"
        :is-bow="isBow"
        :is-crossbow="isCrossbow"
      />
    </div>
    <div class="bottom-line" />
  </div>
</template>

<script>
// import HeroRender from "@/components/hero-render";
const HeroRender = () => import("@/components/hero-render");
import { mapState } from "vuex";
import { PAINT_DELAY, TWO_HANDED_SKILLS } from "@/api";

export default {
  name: "player-view",
  components: { HeroRender },
  data() {
    return {
      init: false,
    };
  },
  computed: {
    ...mapState("calculator", [
      "faction",
      "slots",
      "gender",
      "haircutId",
      "hairColor",
    ]),
    helmet() {
      if (this.slots[6] !== null) {
        return this.slots[6].head;
      }
      if (this.slots[1] !== null) {
        return this.slots[1].look;
      }
      return null;
    },
    helmetColor() {
      if (this.slots[6] !== null) {
        return this.slots[6].headColor;
      }
      if (this.slots[1] !== null) {
        return this.slots[1].overlay;
      }
      return null;
    },
    helmetParam() {
      if (this.slots[6] !== null) {
        return this.slots[6].render;
      }
      if (this.slots[1] !== null) {
        return this.slots[1].bound;
      }
      return null;
    },
    chest() {
      if (this.slots[6] !== null) {
        return this.slots[6].body;
      }
      if (this.slots[4] !== null) {
        return this.slots[4].look;
      }
      return null;
    },
    chestColor() {
      if (this.slots[6] !== null) {
        return this.slots[6].bodyColor;
      }
      if (this.slots[4] !== null) {
        return this.slots[4].overlay;
      }
      return null;
    },
    feet() {
      if (this.slots[6] !== null) {
        return this.slots[6].legs;
      }
      if (this.slots[10] !== null) {
        return this.slots[10].look;
      }
      return null;
    },
    feetColor() {
      if (this.slots[6] !== null) {
        return this.slots[6].legsColor;
      }
      if (this.slots[10] !== null) {
        return this.slots[10].overlay;
      }
      return null;
    },
    hands() {
      if (this.slots[6] !== null) {
        return this.slots[6].hand;
      }
      if (this.slots[8] !== null) {
        return this.slots[8].look;
      }
      return null;
    },
    handsColor() {
      if (this.slots[6] !== null) {
        return this.slots[6].handColor;
      }
      if (this.slots[8] !== null) {
        return this.slots[8].overlay;
      }
      return null;
    },
    cape() {
      if (this.slots[6] !== null) {
        return this.slots[6].cape;
      }
      if (this.slots[0] !== null) {
        return this.slots[0].look;
      }
      return null;
    },
    capeColor() {
      if (this.slots[6] !== null) {
        return this.slots[6].capeColor;
      }
      if (this.slots[0] !== null) {
        return this.slots[0].overlay;
      }
      return null;
    },
    leftWeapon() {
      if (this.slots[3] !== null) {
        return this.slots[3].look;
      }
      return null;
    },
    leftWeaponColor() {
      if (this.slots[3] !== null) {
        return this.slots[3].overlay;
      }
      return null;
    },
    is2Hand() {
      if (this.slots[3] !== null) {
        return (
          TWO_HANDED_SKILLS.includes(this.slots[3].skill) ||
          this.slots[3].skill === 21
        );
      }
      return false;
    },
    rightWeapon() {
      if (this.slots[5] !== null) {
        return this.slots[5].look;
      }
      return null;
    },
    rightWeaponColor() {
      if (this.slots[5] !== null) {
        return this.slots[5].overlay;
      }
      return null;
    },
    isShield() {
      if (this.slots[5] !== null) {
        return this.slots[5].skill === 40;
      }
      return false;
    },
    isBow() {
      if (this.slots[3] !== null) {
        return this.slots[3].skill === 34;
      }
      return false;
    },
    isCrossbow() {
      if (this.slots[3] !== null) {
        return this.slots[3].skill === 97;
      }
      return false;
    },
  },
  mounted() {
    setTimeout(() => (this.init = true), PAINT_DELAY);
  },
};
</script>

<style scoped>
.box {
  position: relative;
  width: 82px;
  height: 108px;
  margin: 4px auto;
  z-index: 2;
}
.box::before,
.box::after {
  position: absolute;
  content: "";
  display: block;
  background-image: url("../assets/preview-border-v.png");
  background-size: 6px 104px;
  background-repeat: no-repeat;
  background-position: 0 2px;
  image-rendering: pixelated;
  width: 6px;
  height: 108px;
  top: 0;
  z-index: -1;
}
.box::before {
  left: 0;
}
.box::after {
  right: 0;
}
.top-line,
.bottom-line {
  background-image: url("../assets/preview-border-h.png");
  background-size: 2px 12px;
  background-repeat: repeat-x;
  image-rendering: pixelated;
  height: 6px;
}
.top-line::before,
.top-line::after,
.bottom-line::before,
.bottom-line::after {
  position: absolute;
  content: "";
  display: block;
  background-image: url("../assets/preview-corner.png");
  background-size: 8px;
  image-rendering: pixelated;
  width: 8px;
  height: 8px;
  z-index: 1;
}
.top-line::before {
  top: 0;
  left: 0;
}
.top-line::after {
  top: 0;
  right: 0;
}
.bottom-line::before {
  bottom: 0;
  left: 0;
}
.bottom-line::after {
  bottom: 0;
  right: 0;
}
.content {
  height: 96px;
  width: 72px;
  margin: 0 auto;
  background-color: #012244;
  background-image: url("../assets/platform.png");
  background-size: 68px 32px;
  background-repeat: no-repeat;
  image-rendering: pixelated;
  /*background-position: 2px 58px;*/
  background-position: 2px 68px;
}
.hero {
  position: relative;
  left: 4px;
}
</style>
