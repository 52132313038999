<template>
  <nav class="top-menu">
    <div class="item" @click.stop.prevent="sidebar">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 80"
        width="32"
        height="40"
        fill="#fff"
      >
        <path d="M0 0h100v15H0zm0 25h100v15H0zm0 25h100v15H0z" />
      </svg>
    </div>
    <div class="sub-menu right">
      <router-link
        :to="{ name: 'SettingsPage' }"
        class="item"
        :title="$t('label.settings')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="32"
          viewBox="0 0 48 48"
          width="32"
        >
          <path
            fill="#fff"
            d="M38.86 25.95c.08-.64.14-1.29.14-1.95s-.06-1.31-.14-1.95l4.23-3.31c.38-.3.49-.84.24-1.28l-4-6.93c-.25-.43-.77-.61-1.22-.43l-4.98 2.01c-1.03-.79-2.16-1.46-3.38-1.97L29 4.84c-.09-.47-.5-.84-1-.84h-8c-.5 0-.91.37-.99.84l-.75 5.3c-1.22.51-2.35 1.17-3.38 1.97L9.9 10.1c-.45-.17-.97 0-1.22.43l-4 6.93c-.25.43-.14.97.24 1.28l4.22 3.31C9.06 22.69 9 23.34 9 24s.06 1.31.14 1.95l-4.22 3.31c-.38.3-.49.84-.24 1.28l4 6.93c.25.43.77.61 1.22.43l4.98-2.01c1.03.79 2.16 1.46 3.38 1.97l.75 5.3c.08.47.49.84.99.84h8c.5 0 .91-.37.99-.84l.75-5.3c1.22-.51 2.35-1.17 3.38-1.97l4.98 2.01c.45.17.97 0 1.22-.43l4-6.93c.25-.43.14-.97-.24-1.28l-4.22-3.31zM24 31c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"
          />
        </svg>
      </router-link>
      <router-link
        :to="{ name: authorized ? 'ProfilePage' : 'LoginPage' }"
        class="item"
        :title="$t(authorized ? 'label.profile' : 'action.enter')"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#fff">
          <circle cx="12" cy="8" r="4" />
          <path
            d="M20 19v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-1a6 6 0 0 1 6-6h4a6 6 0 0 1 6 6z"
          />
        </svg>
      </router-link>
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "top-menu",
  events: {
    side: {
      custom: true,
    },
  },
  methods: {
    sidebar() {
      this.$emit("side");
    },
  },
  computed: {
    ...mapState({
      authorized: (state) => state.token.length > 0,
    }),
  },
};
</script>

<style scoped>
.top-menu {
  background-image: url("../assets/bg.png");
  background-size: 112px 56px;
  border-bottom: 1px solid #e7cb67;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
  image-rendering: pixelated;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.top-menu,
.sub-menu {
  display: flex;
  flex-direction: row;
}
.item {
  background-color: transparent;
  border: none;
  border-right: 1px solid #585858;
  color: #fff;
  display: block;
  height: 36px;
  line-height: 36px;
  min-width: 40px;
  padding: 4px 12px;
  text-align: center;
  text-decoration: none;
  transition-delay: 16ms;
  transition-duration: 64ms;
  transition-property: background-color;
  cursor: pointer;
}
.item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.item > img {
  width: 32px;
  height: 32px;
}
.sub-menu.right {
  margin-left: auto;
  order: 2;
}
</style>
