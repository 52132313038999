<template>
  <div id="app">
    <div class="clouds" v-if="allowClouds">
      <float-cloud :key="i + '_' + t" :type="t" v-for="(t, i) in clouds" />
    </div>
    <div class="page">
      <top-menu @side="side" />
      <side-menu ref="side" />
      <div class="wrapper">
        <router-view />
      </div>
      <float-button v-if="notification" @click.native="showNotification">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
          <path
            fill="#fff"
            d="M5.705 3.71l-1.41-1.42C1 5.563 1 7.935 1 11h1l1-.063C3 8.009 3 6.396 5.705 3.71zm13.999-1.42l-1.408 1.42C21 6.396 21 8.009 21 11l2-.063c0-3.002 0-5.374-3.296-8.647zM12 22a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22zm7-7.414V10c0-3.217-2.185-5.927-5.145-6.742a1.99 1.99 0 0 0-3.71 0C7.184 4.073 5 6.783 5 10v4.586l-1.707 1.707A.996.996 0 0 0 3 17v1a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-1a.996.996 0 0 0-.293-.707L19 14.586z"
          />
        </svg>
      </float-button>
      <float-button v-if="!notification && !check" no-back>
        <img
          :src="love"
          alt="Be with Ukraine"
          width="48"
          height="48"
          title="Be with Ukraine"
        />
      </float-button>
      <message-info
        v-if="notification && notification.display"
        @close="notification.display = false"
      >
        <div v-html="notification.message" class="notify-text" />
      </message-info>
    </div>
  </div>
</template>

<script>
const FloatCloud = () => import("@/components/float-cloud");
// import MessageLoader from "@/components/msg/message-loader";
import TopMenu from "@/components/top-menu";
import SideMenu from "@/components/side-menu";
import { mapState } from "vuex";
import { getUser } from "@/router";
const FloatButton = () => import("@/components/float-button");
import { getGlobalNotify, NOTIFICATION_DELAY, PAINT_DELAY } from "@/api";
const MessageInfo = () => import("@/components/msg/message-info");

export default {
  name: "App",
  components: {
    MessageInfo,
    FloatButton,
    SideMenu,
    TopMenu,
    // MessageLoader,
    FloatCloud,
  },
  data() {
    // noinspection JSUnresolvedVariable
    return {
      /* global _CLOUDS_ */
      clouds: _CLOUDS_.reduce((a, c) => {
        const r = Math.random() * 2;
        for (let i = 0; i < r; i++) {
          a.push(c);
        }
        return a;
      }, []),
      check: true,
      notification: null,
      showLove: false,
      allowClouds: false,
    };
  },
  mounted() {
    if (!this.loading) {
      setTimeout(() => {
        getUser();
        this.getGlobalNotify();
      }, NOTIFICATION_DELAY);
    }
    setTimeout(() => (this.allowClouds = true), PAINT_DELAY);
  },
  computed: {
    ...mapState(["loading"]),
    locale() {
      return this.$route.params.lang;
    },
    love() {
      return require("@/assets/ua.gif");
    },
  },
  methods: {
    side() {
      this.$refs.side.open();
    },
    getGlobalNotify() {
      getGlobalNotify(this.locale)
        .then((e) => {
          if (e.data.id !== 0) {
            this.notification = { ...e.data, display: false };
          }
        })
        .catch(() => {
          console.info("[App] No global notifications");
          this.showLove = true;
        })
        .finally(() => {
          this.check = false;
        });
    },
    showNotification() {
      if (this.notification) {
        this.notification.display = true;
      }
    },
  },
  watch: {
    locale() {
      setTimeout(() => this.getGlobalNotify(), NOTIFICATION_DELAY);
    },
  },
};
</script>

<!--suppress CssUnusedSymbol -->
<style>
html,
body {
  padding: 0;
  margin: 0;
  background-color: #a7dbef;
}
body {
  overscroll-behavior: contain;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.clouds {
  position: absolute;
  z-index: -1;
}
.wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 54px;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
a {
  text-decoration: none;
}

.item-color-0 {
  color: #c3c3c3;
}
.item-color-1 {
  color: #66ff66;
}
.item-color-2 {
  color: #7cb7ff;
}
.item-color-3 {
  color: #ff66ff;
}
.item-color-4 {
  color: #ff6666;
}
.item-color-5 {
  color: #ffffff;
}
.item-color-6 {
  color: #ffcc00;
}
.item-color-7 {
  color: #ffff66;
}
.item-color-8 {
  color: #7ddc78;
}
.bind-color-0 {
  color: #fff;
}
.bind-color-1 {
  color: #ff8f00;
}
.bind-color-2 {
  color: #ffff6f;
}
.bind-color-3 {
  color: #ffff66;
}
.notify-text {
  padding: 0 54px;
  text-align: justify;
}

.page-wrap {
  padding: 8px;
}
</style>
