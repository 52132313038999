<template>
  <div
    class="icon"
    :style="{
      backgroundImage: icon > 0 ? `url('/icons/${icon}.webp')` : 'none',
    }"
    :class="{ show: icon !== 0 }"
  />
</template>

<script>
export default {
  name: "simple-icon",
  props: {
    icon: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.icon {
  width: 60px;
  height: 60px;
  background-size: 60px;
  background-repeat: no-repeat;
  image-rendering: pixelated;
  border: 2px solid #e7c642;
  opacity: 0;
  transition: opacity 0.4s;
}
.icon.show {
  opacity: 1;
}
</style>
