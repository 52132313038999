const OPAQUE_KEY = "cfg.opaque";
const RENDER_KEY = "cfg.no-render";

export default {
  namespaced: true,
  state: {
    opaqueBackground: JSON.parse(localStorage.getItem(OPAQUE_KEY) || "false"),
    disableRender: JSON.parse(localStorage.getItem(RENDER_KEY) || "false"),
  },
  mutations: {
    setOpaque(state, val) {
      state.opaqueBackground = val;
      localStorage.setItem(OPAQUE_KEY, val);
    },
    setDisableRender(state, val) {
      state.disableRender = val;
      localStorage.setItem(RENDER_KEY, val);
    }
  },
};
