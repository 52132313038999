<template>
  <label class="label" :class="{ warning }">
    <slot />
  </label>
</template>

<script>
export default {
  name: "field-label",
  props: {
    warning: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
.label {
  color: #fff;
  text-shadow: -1px 0 1px #000, 0 -1px 1px #000, 1px 0 1px #000, 0 1px 1px #000;
  padding: 4px;
  font-size: 1.2em;
  line-height: 24px;
  display: block;
}
.label.warning {
  color: #ffa900;
}
</style>
