<template>
  <div
    class="slot"
    :class="[
      mode,
      type,
      icon !== null ? 'icon' : '',
      levelClass,
      gender !== null ? 'gender-' + gender : '',
    ]"
    tabindex="0"
    @click="click"
  >
    <div class="icon" v-show="icon !== null || item !== null" :style="style" />
    <span
      v-show="type !== 'level' && (level > 0 || item !== null)"
      v-text="textLevel"
      class="item-level"
    />
    <span
      v-show="type === 'level' && level > 0"
      v-text="textLevel"
      class="level"
    />
    <span
      v-show="value !== 0"
      v-text="textValue"
      :class="['value', (valueParams & 2) !== 0 ? 'extra' : '']"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { formatValue } from "@/utils";

const modes = ["normal", "green", "red"];
const bgs = [
  "cape",
  "helmet",
  "amulet",
  "weapon-left",
  "weapon-right",
  "body",
  "glove",
  "outfit",
  "belt",
  "ring",
  "boots",
  "decoration",
  "crystal",
  "rune",
  "basic",
  "level",
  "bracelet",
  "summon"
];

export default {
  name: "icon-slot",
  props: {
    mode: {
      type: String,
      required: false,
      default: "normal",
      validator: (v) => modes.includes(v),
    },
    type: {
      type: String,
      required: false,
      default: "basic",
      validator: (v) => bgs.includes(v),
    },
    icon: {
      type: Number,
      required: false,
      default: null,
    },
    level: {
      type: Number,
      required: false,
      default: 0,
    },
    item: {
      type: Object,
      required: false,
      default: null,
    },
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    valueParams: {
      type: Number,
      required: false,
      default: 0,
    },
    gender: {
      type: Number,
      required: false,
      default: null,
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
  computed: {
    ...mapState("calculator", ["expose"]),
    style() {
      if (this.icon) {
        return {
          backgroundImage: `url('/icons/${this.icon}.webp')`,
        };
      }
      if (this.item !== null) {
        return {
          backgroundImage: `url('/icons/${this.item.icon}.webp')`,
        };
      }
      return {};
    },
    textLevel() {
      if (this.level) {
        return this.level;
      }
      if (this.item !== null) {
        return this.item.level;
      }
      return "";
    },
    levelClass() {
      if (this.level === 0) {
        return "";
      } else if (this.level <= 12) {
        return "l1";
      } else if (this.level <= 21) {
        return "l2";
      } else if (this.level <= 31) {
        return "l3";
      }
      return "l4";
    },
    textValue() {
      if (this.value === 0 || (this.valueParams & 8) !== 0) {
        return "";
      }
      return formatValue(this.expose, this.valueParams, this.value);
    },
  },
};
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
.slot {
  background-image: url("../assets/slots.png");
  background-size: 264px 174px;
  image-rendering: pixelated;
  background-repeat: no-repeat;
  width: 88px;
  height: 88px;
  display: inline-block;
  position: relative;
  transform-style: preserve-3d;
  margin: 0;
}
.slot:active,
.slot:focus {
  outline: none;
  box-shadow: none;
}
.slot.normal {
  background-position: 0 -88px;
}
.slot.normal:active,
.slot.normal:focus {
  background-position: 0 0;
}
.slot.red {
  background-position: -88px -88px;
}
.slot.red:active,
.slot.red:focus {
  background-position: -88px 0;
}
.slot.green {
  background-position: -176px -88px;
}
.slot.green:active,
.slot.green:focus {
  background-position: -176px 0;
}
.slot::before {
  content: "";
  display: block;
  position: absolute;
  left: 14px;
  top: 13px;
  width: 60px;
  height: 60px;
  background-image: url("../assets/slot-bg.png");
  background-size: 60px 900px;
  background-repeat: no-repeat;
  image-rendering: pixelated;
  transform: translateZ(-1px);
}
.slot.cape::before {
  background-position: 0 0;
}
.slot.helmet::before {
  background-position: 0 -60px;
}
.slot.amulet::before {
  background-position: 0 -120px;
}
.slot.weapon-left::before {
  background-position: 0 -180px;
  transform: translateZ(-1px) scaleX(-1);
}
.slot.weapon-right::before {
  background-position: 0 -180px;
}
.slot.body::before {
  background-position: 0 -240px;
}
.slot.glove::before {
  background-position: 0 -300px;
}
.slot.outfit::before {
  background-position: 0 -360px;
}
.slot.belt::before {
  background-position: 0 -420px;
}
.slot.ring::before {
  background-position: 0 -480px;
}
.slot.boots::before {
  background-position: 0 -540px;
}
.slot.decoration::before {
  background-position: 0 -600px;
}
.slot.crystal::before {
  background-position: 0 -660px;
}
.slot.rune::before {
  background-position: 0 -720px;
}
.slot.bracelet::before {
  background-position: 0 -780px;
}
.slot.summon::before {
  background-position: 0 -840px;
}
.slot.basic::before,
.slot.l1::before,
.slot.l2::before,
.slot.l3::before,
.slot.l4::before,
.slot.l5::before,
.slot.l6::before,
.slot.l7::before {
  background-image: url("../assets/lvls.png");
  background-size: 60px 420px;
}
.slot.basic::before,
.slot.l1::before {
  background-position: 0 0;
}
.slot.l2::before {
  background-position: 0 -60px;
}
.slot.l3::before {
  background-position: 0 -120px;
}
.slot.l4::before {
  background-position: 0 -180px;
}
.slot.l5::before {
  background-position: 0 -240px;
}
.slot.l6::before {
  background-position: 0 -300px;
}
.slot.l7::before {
  background-position: 0 -360px;
}
.slot.icon::before {
  opacity: 0;
}
.slot.gender-0::before,
.slot.gender-1::before {
  background-image: url("../assets/gender.png");
  background-size: 58px 116px;
}
.slot.gender-0::before {
  background-position: 0 0;
}
.slot.gender-1::before {
  background-position: 0 -58px;
}
.slot > .icon {
  background-size: 60px;
  image-rendering: pixelated;
  width: 60px;
  height: 60px;
  margin: 12px 14px;
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(-1px);
}
.slot > .item-level {
  position: absolute;
  top: 15px;
  left: 16px;
  color: #ffff00;
  text-shadow: -1px 0 1px #000, 0 -1px 1px #000, 1px 0 1px #000, 0 1px 1px #000;
  font-weight: bold;
}
.slot > .level {
  position: absolute;
  text-shadow: -1px 0 1px #000, 0 -1px 1px #000, 1px 0 1px #000, 0 1px 1px #000;
  font-size: 2.4rem;
  color: #fff;
  font-weight: bold;
  text-align: center;
  display: block;
  line-height: 60px;
  width: 100%;
  top: 16px;
}
.slot > .value {
  position: absolute;
  text-shadow: -1px 0 1px #000, 0 -1px 1px #000, 1px 0 1px #000, 0 1px 1px #000;
  width: 100%;
  bottom: 12px;
  text-align: center;
  font-weight: bold;
}
.slot > .value.extra {
  color: #00ff00;
}
</style>
