import { render, staticRenderFns } from "./field-label.vue?vue&type=template&id=e0db9082&scoped=true&"
import script from "./field-label.vue?vue&type=script&lang=js&"
export * from "./field-label.vue?vue&type=script&lang=js&"
import style0 from "./field-label.vue?vue&type=style&index=0&id=e0db9082&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0db9082",
  null
  
)

export default component.exports