import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
// import upload from "./upload";
import calculator from "./calculator";
import settings from "@/store/settings";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token") || "",
    id: 0,
    username: "",
    loading: false,
    offline: false,
    rights: [],
  },
  mutations: {
    setLoading(state, load) {
      state.loading = load;
    },
    setToken(state, { token, username, rights, id }) {
      state.token = token;
      state.username = username;
      state.rights = rights;
      state.id = id;
      localStorage.setItem("token", token);
    },
    setOffline(state, offline) {
      state.offline = offline;
    },
  },
  actions: {
    checkSession(ctx) {
      if (ctx.state.token.length < 1) {
        ctx.commit("setLoading", false);
        return;
      }
      ctx.commit("setLoading", true);
      return axios
        .get("/api/auth/verify", {
          headers: {
            Authorization: `Bearer ${ctx.state.token}`,
          },
        })
        .then((e) => {
          if (e.status !== 200) {
            ctx.commit("setToken", {
              id: 0,
              token: "",
              username: "",
              rights: [],
            });
            return {
              token: "",
              rights: [],
            };
          } else {
            let token = ctx.state.token;
            if (e.data.data.token) {
              token = e.data.data.token;
              localStorage.setItem("token", token);
            }
            axios.defaults.headers["Authorization"] = `Bearer ${token}`;
            ctx.dispatch("upload/checkSession");
            ctx.commit("setToken", {
              id: e.data.data.id,
              token,
              username: e.data.data.username,
              rights: e.data.data.rights,
            });
            return {
              token: token,
              rights: e.data.data.rights,
            };
          }
        })
        .catch((e) => {
          if (
            !e.response ||
            (e.response.status !== 401 && e.response.status !== 403)
          ) {
            ctx.commit("setOffline", true);
          } else {
            ctx.commit("setToken", {
              id: 0,
              token: "",
              username: "",
              rights: [],
            });
          }
          return {
            token: "",
            rights: [],
          };
        })
        .finally(() => {
          ctx.commit("setLoading", false);
        });
    },
    login(ctx, { username, password }) {
      return axios
        .post("/api/auth/login", {
          username,
          password,
        })
        .then((e) => {
          if (e.status === 200) {
            axios.defaults.headers[
              "Authorization"
            ] = `Bearer ${e.data.data.token}`;
            ctx.dispatch("upload/checkSession");
            ctx.commit("setToken", {
              id: e.data.data.id,
              token: e.data.data.token,
              username: e.data.data.username,
              rights: e.data.data.rights,
            });
          }
          return 0;
        })
        .catch((e) => {
          if (
            !e.response ||
            (e.response.status !== 401 && e.response.status !== 403)
          ) {
            ctx.commit("setOffline", true);
            return -1;
          }
          return 1;
        });
    },
  },
  modules: {
    // upload,
    calculator,
    settings,
  },
});
